/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'gatsby';
import { Layout, SEO } from '@/components';

export const Head = ({ location }) => {
  const title = 'Privacy Policy';
  const description = 'Njano is a cloud-based flexible office management solution designed to manage workplaces';
  return <SEO title={title} description={description} location={location} />;
};

const PrivacyPolicy = () => (
  <Layout dark logo>
    <div className="md:max-w-5xl container w-full px-16 pt-20 mx-auto bg-white shadow-lg">
      <div className="md:px-6 w-full px-4 text-sm md:text-md leading-normal text-gray-800">
        <div className="font-content">
          <span className="text-primary md:text-sm space-y-8 text-base font-bold">
            <Link to="/" className="bg-secondary md:text-sm px-6 py-2 text-base font-bold text-white no-underline">
              &#8592; BACK TO HOME
            </Link>
            <h1 className="font-heading md:text-5xl pt-6 pb-2 text-3xl font-bold text-gray-900 break-normal">
              Privacy Policy
            </h1>
          </span>
        </div>
        <p className="py-2">
          This Privacy Policy (hereinafter the &#34;Policy&#34;) informs you on how Njanoo, a limited liability company
          with corporate registration number 1782480/L/A/M/00 and a registered office located at 17, street lac el
          Bibane, les berges du lac ,1053, Tunisia, processes, as a data controller, your personal data collected via
          its internet Platform Njano (hereinafter &#34;Njano&#34;) when you use its Services. The Policy is an integral
          part of the General Terms of Use (hereinafter the &#34;ToU&#34;) that you accepted as a{' '}
          <Link to="/terms/hub-terms" className="text-blue-600">
            space manager
          </Link>{' '}
          or a{' '}
          <Link to="/terms/general-terms" className="text-blue-600">
            user
          </Link>{' '}
          when you created your account. Capitalized words in this Policy that are not directly defined herein shall
          have the meaning given to them in the ToU and/or in the General Terms of Use if you have registered.
        </p>
        <p className="py-2">
          In relation to the provision of these services, Njanoo will collect certain personal data about you. Privacy
          and data protection being a major issue for Njanoo, this Policy aims at informing you about the use of your
          data and your rights in relation to such data.
        </p>
        <p className="py-2">
          This Policy implements the principles set out in Regulation (EU) 2016/679 - General Data Protection Regulation
          ("GDPR") and Tunisian organic law No. 63 of 27 July 2004.
        </p>
        <p className="py-2">
          Njanoo pays constant attention to its Users' data. We may therefore be required to modify, supplement or
          update this Policy periodically. We may also make the necessary changes in order to comply with changes in the
          legislation and regulations in force. Whenever possible, we will notify you of any significant changes.
          However, you will be notified to review the most current version which will be shared with you.
        </p>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          What personal data does Njanoo collect?
        </h2>
        <div className="py-2">
          We collect personal data that you provide upon creating your account on our Platform.
          <br />
          Among this data, we collect in particular:
          <ul className="list-inside list-disc space-y-1 my-2">
            <li>your surname(s) and first name(s);</li>
            <li>your email address;</li>
            <li>your country and city of residence, your professional situation and your skills;</li>
            <li>
              you also have the option of providing us with other data concerning your personal details, your startup
              info,.. etc;
            </li>
            <li>
              Your platform usage, including information about the content you view and browsing activity inside Njano;
            </li>
            <li>
              Your IP address used to sign up and login to the platform for security and fraud prevention purposes;
            </li>
          </ul>
        </div>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          Why do we collect this data and for what purpose?{' '}
        </h2>
        <div className="py-2">
          The collection of your data is necessary in order to provide you with the Services offered by Njano by Njanoo
          in the best possible conditions.
          <br />
          The purposes are as follows:
          <ul className="list-inside list-disc space-y-1 my-2">
            <li>To enable you to create your account and thus access the Services offered via the Platform;</li>
            <li>To enable us to communicate with you in the context of the said Services;</li>
            <li>
              To compile statistics on the use of all or part of the Services for the purpose of managing and improving
              the Services;
            </li>
          </ul>
          In the context of satisfaction surveys, in order to improve the Services, we may use a customer satisfaction
          measurement tool.
        </div>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          How long will your data be kept?
        </h2>
        <div className="py-2">
          We keep your data only for as long as is necessary for the purposes for which it was collected, in accordance
          with legal requirements, and in particular:
          <ul className="list-outside list-disc space-y-1 my-2">
            <li>
              Login and profile data:
              <ul className="list-inside list-disc">
                <li>5 years after your account has been deleted;</li>
                <li>
                  In the event that your account has been suspended or blocked, we will keep your data for a period of 5
                  years from the date of suspension in order to avoid any circumvention by you of the rules in force on
                  our Platform.
                </li>
              </ul>
            </li>
            <li>Contract data: 10 years</li>
          </ul>
          Thereafter, we may continue to process certain data for statistical purposes, on the basis of our legitimate
          interest as data controller, without revealing your identity and by implementing adequate security measures.
        </div>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          With whom is your data shared?
        </h2>
        <div className="py-2">
          Your personal data may be shared with: Your personal data may be shared with:
          <ul className="list-inside list-disc space-y-1 my-2">
            <li>The Njanoo management team, in particular the logistics and IT departments;</li>
            <li>Our subcontractors, within the limits necessary for the missions entrusted to them;</li>
            <li>
              Organizations, legal auxiliaries and ministerial officers, within the framework of their debt collection
              mission.
            </li>
          </ul>
          <p className="py-2">
            When your data is shared with our subcontractors, this is done with respect for your data, for the purposes
            detailed above, and by requiring our subcontractors to respect appropriate security measures.
          </p>
          <p className="py-2">
            We provide a legal framework for transfers by means of contractual commitments with our subcontractors to
            ensure a high level of security.
          </p>
          <p className="py-2">
            Njanoo does not sell or rent your personal data to third parties for marketing purposes under any
            circumstances.
          </p>
          <p className="py-2">
            In addition, Njanoo does not disclose your personal data to third parties, unless (1) you request or
            authorize the disclosure; (2) the disclosure is required to process transactions or provide services that
            you have requested; (3) Njanoo is compelled to do so by a governmental authority or regulatory body, in the
            event of a judicial requisition, subpoena or other similar governmental or judicial requirement, or to
            establish or defend a legal claim; or (4) the third party is acting as an agent or subcontractor of Njanoo
            in performing the Services.
          </p>
        </div>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          How are your personal data protected?
        </h2>
        <p className="py-2">
          As the security of your data is of paramount importance to us, we have put in place technical and
          organizational measures to prevent your data from being accidentally lost, used, altered, or made available to
          the public without your authorization.
        </p>
        <p className="py-2">We have also put in place measures to prevent unauthorized access to your data.</p>
        <p className="py-2">
          However, the security of personal data also depends on Users who agree to keep their login and password
          confidential. Members also undertake not to share their account and to declare to Njanoo any unauthorized use
          of said account as soon as they become aware of it.
        </p>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          What are your rights?
        </h2>
        <div className="py-2">
          <p>
            In accordance with the GDPR and the Tunisian organic law No. 63 of 27 July 2004, subject to providing proof
            of your identity, you benefit from the following rights:
          </p>
          <ul className="list-inside list-disc space-y-1 my-2">
            <li>
              Right of Access: this is the possibility to request information on the data processing as well as a copy
              of your data;
            </li>
            <li>Right to Rectification: this is the right to ask to rectify, modify or update your data;</li>
            <li>Right to Erasure: i.e. the right, in certain cases, to request the erasure of your data;</li>
            <li>
              Right to the Limitation of the processing: this is the possibility to ask, under certain conditions, for
              the limitation of the processing operations to the conservation of your data;
            </li>
            <li>Right to Object: i.e. the possibility, in certain cases, to object to the processing of your data;</li>
            <li>
              Right to Portability: this is the faculty, under certain conditions, to request to receive your personal
              data or their transmission to another data controller.
            </li>
          </ul>
          <p>
            You can exercise these rights at any time free of charge via the platform or by sending an email to
            conact@njano.org and we will get back to you as soon as possible.
          </p>
        </div>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          Modification of this Policy
        </h2>
        <p className="py-2">
          Njanoo may modify this Policy occasionally, in particular to reflect any regulatory and legislative changes,
          changes to the conditions of the processing of your personal data, and for any other reason that Njanoo would
          consider useful, at its discretion.
        </p>
        <p className="py-2">
          Any substantial modification of the Policy will be the subject of a specific mention on the Platform and/or a
          direct communication by email.
        </p>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          How to contact us?
        </h2>
        <p className="py-2">
          If you have any questions about our use of your data, or about this Policy, please send an email to
          contact@njano.org
        </p>
        <p className="mt-4 italic text-sm">Publication date V.1 : july, 2024</p>
      </div>
    </div>
  </Layout>
);

export default PrivacyPolicy;
